import React, { PropsWithChildren, useContext, useState } from 'react';
import { hotbarPresets } from '@components/DateRangePickerPopover/rangePickerPresets';
import { DateTimeRange } from '../types';

type DateTimeRangeContextType = {
  dateTimeRange: DateTimeRange;
  setDateTimeRange: React.Dispatch<React.SetStateAction<DateTimeRange>>;
};

export const DateTimeRangeContext = React.createContext<Nullable<DateTimeRangeContextType>>(null);

export const useDateTimeRangeContext = () => {
  const context = useContext(DateTimeRangeContext);

  if (!context) {
    throw new Error('useDateTimeRange must be used within a DateTimeRangeContext.Provider');
  }

  return context;
};

export const DateTimeRangeProvider = ({ children }: PropsWithChildren) => {
  const initialRange = hotbarPresets[hotbarPresets.length - 1].range() || {
    start: new Date(),
    end: new Date(),
  };

  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange>({
    start: initialRange.startDate,
    end: initialRange.endDate,
  });

  return (
    <DateTimeRangeContext.Provider value={{ dateTimeRange, setDateTimeRange }}>
      {children}
    </DateTimeRangeContext.Provider>
  );
};
