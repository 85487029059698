import { useEffect, useRef } from 'react';
import { useGetAnalysisQuery } from '@apiRtk/analysis';
import { useGetSiteQuery } from '@apiRtk/sites';
import { HttpStatusCode } from '@appTypes/api/error';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import DateRangePanel from '@components/ChartNew/DateRangePanel/DateRangePanel';
import { DateTimeRangeProvider } from '@components/ChartNew/DateRangePanel/DateTimeRangeContext';
import EchartWrapper from '@components/ChartNew/EchartWrapper';
import { Spinner } from '@components/Spinner';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { MultilineChart } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import { getLastVisitedSitesService } from '@services/lastVisitedSites';
import { MAX_LAST_VISITED_SITES } from '@settings/config';
import { dictionary } from '@settings/dictionary';
import { prepareError } from '@utils/data/prepareError';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const DataAnalysisNew = () => {
  const { currentUser } = useSelector(selectAuthSlice);
  const lastVisitedSites = getLastVisitedSitesService(MAX_LAST_VISITED_SITES);

  const params = useParams();
  const siteId = parseInt(params.id!, 10);

  const { data: siteData } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const refs = useRef<Array<Nullable<ReactECharts>>>([]);

  const { refetch, data: graphs, isLoading, error, isError } = useGetAnalysisQuery({ siteId });

  useEffect(() => {
    if (siteData?.name) {
      lastVisitedSites.addSite({
        id: siteData.id,
        name: siteData.name,
      });
    }
  }, [siteData, lastVisitedSites]);

  if (!graphs || isLoading) {
    return <Spinner />;
  }

  if (isError) {
    const errorMessage =
      prepareError(error)?.status === HttpStatusCode.NOT_FOUND
        ? dictionary.selectedSiteNotFound
        : dictionary.errorCommon;

    return <AlertWithRefetch onRetryClick={refetch}>{errorMessage}</AlertWithRefetch>;
  }

  const currentSite = currentUser?.sites.find(({ id }) => id === siteId);
  const pageTitle = `${currentSite?.name} - Data Analysis`;

  return (
    <Box>
      <TitleBar title={pageTitle} IconComponent={MultilineChart} />
      <DateTimeRangeProvider>
        <DateRangePanel />
        {graphs.map(({ graphId, title, dataLink }, index) => (
          <Accordion
            key={graphId}
            style={{ marginTop: 10, backgroundColor: 'white' }}
            defaultExpanded
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="body1">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box marginTop="auto" marginBottom="auto">
                <EchartWrapper
                  refs={refs}
                  groupRef={(el) => {
                    refs.current[index] = el;
                  }}
                  height={250}
                  deviceId={graphId}
                  dataLink={dataLink}
                  index={index}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </DateTimeRangeProvider>
    </Box>
  );
};

export default DataAnalysisNew;
