import { useGetGraphQuery } from '@apiRtk/analysis';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { PaperLight } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { Box } from '@mui/material';
import { dictionary } from '@settings/dictionary';
import ReactECharts, { EChartsReactProps } from 'echarts-for-react';
import { useDateTimeRangeContext } from './DateRangePanel/DateTimeRangeContext';
import ECharts from './Echarts';

type EChartsWrapperProps = Omit<EChartsReactProps, 'option'> & {
  groupRef: React.LegacyRef<ReactECharts>;
  deviceId: string;
  refs: React.MutableRefObject<Nullable<ReactECharts>[]>;
  height: number;
  index: number;
  dataLink: string;
};

const EchartWrapper = ({
  groupRef,
  deviceId,
  refs,
  height,
  index,
  dataLink,
  ...props
}: EChartsWrapperProps) => {
  const {
    dateTimeRange: { start, end },
    setDateTimeRange,
  } = useDateTimeRangeContext();

  const {
    data: graphData,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useGetGraphQuery(
    { dataLink, startDate: start, endDate: end, limit: 1000 },
    { skip: !dataLink },
  );

  const onEvents = {
    dataZoom: (params: { batch?: { endValue: number; startValue: number }[] }) => {
      if (params?.batch) {
        const { endValue, startValue } = params.batch[0];
        setDateTimeRange({ start: new Date(startValue), end: new Date(endValue) });
      }
    },
  };

  const isLoadingOrFetching = isLoading || isFetching;
  const shouldShowError = isError || !graphData;

  let content;

  if (isLoadingOrFetching) {
    content = <Spinner />;
  } else if (shouldShowError) {
    content = (
      <AlertWithRefetch onRetryClick={refetch}>{dictionary.graphCommonError}</AlertWithRefetch>
    );
  } else {
    content = (
      <ECharts
        height={height}
        refs={refs}
        deviceId={deviceId}
        groupRef={groupRef}
        loading={isLoadingOrFetching}
        graphData={graphData}
        onEvents={onEvents}
        {...props}
      />
    );
  }

  return (
    <PaperLight
      sx={{ minHeight: height, display: 'flex', flexDirection: 'column' }}
      data-testid="historical-graph-wrapper"
    >
      <Box marginY="auto">{content}</Box>
    </PaperLight>
  );
};

export default EchartWrapper;
